<template>
    <div id="courseIntroductionDetails">
        <div class="box">
            <div class="breadCrumbs">
                <span style="color: #999999;cursor: pointer;" @click="indexClick">个人中心</span>>
                <span>考核</span>
            </div>
            <div class="titleSystemClass" style="margin-bottom:0.1%;">智慧工地考核系统</div>
            <div style="width: 100%;height: 34px;background: #fff;font-size: 14px;display: flex;align-items: center;">
                <div style="margin: 0 1.5%;">
                    <span style="color:#666;">课程名称：</span>
                    <span style="color:#333;">建筑力学</span>
                </div>
                <div style="margin: 0 1%;">
                    <span style="color:#666;">章名称：</span>
                    <span style="color:#333;">模块1 绪论</span>
                </div>
                <div style="margin: 0 1%;">
                    <span style="color:#666;">节任务：</span>
                    <span style="color:#333;">任务1 建筑力学研究对象</span>
                </div>
                <div style="margin: 0 1%;">
                    <span style="color:#666;">习题类型：</span>
                    <span style="color:#333;">课后作业</span>
                </div>
            </div>
            <div class="system">
                <div style="width: 23%;height: 700px; background-color:#fff;">
                    <p style="padding: 20px;">
                        <img src="@/assets/img/icon/datika.png" alt="" srcset="" style="vertical-align: middle;">
                        <span style="padding-left: 15px;font-weight: 800;">答题卡</span>
                    </p>
                    <p style="font-size: 14px;padding-left:20px;padding-top: 10px;">填空题</p>
                    <div style="height: 40px;padding-left: 20px;line-height: 40px;">
                        <span style="color: #76C8F6;float: left;">1</span>
                        <span style="width: 20%;height: 20px;background-color: #F7F8FA;display: block;float: left;margin: 10px;"></span>
                        <span style="width: 20%;height: 20px;background-color: #F7F8FA;display: block;float: left;margin: 10px;"></span>
                        <span style="width: 20%;height: 20px;background-color: #F7F8FA;display: block;float: left;margin: 10px;"></span>
                    </div>
                    <div style="height: 40px;padding-left: 20px;line-height: 40px;">
                        <span style="color: #76C8F6;float: left;">2</span>
                        <span style="width: 20%;height: 20px;background-color: #F7F8FA;display: block;float: left;margin: 10px;"></span>
                        <span style="width: 20%;height: 20px;background-color: #F7F8FA;display: block;float: left;margin: 10px;"></span>
                        <span style="width: 20%;height: 20px;background-color: #F7F8FA;display: block;float: left;margin: 10px;"></span>
                    </div>
                    <div style="height: 40px;padding-left: 20px;line-height: 40px;">
                        <span style="color: #76C8F6;float: left;">3</span>
                        <span style="width: 20%;height: 20px;background-color: #F7F8FA;display: block;float: left;margin: 10px;"></span>
                        <span style="width: 20%;height: 20px;background-color: #F7F8FA;display: block;float: left;margin: 10px;"></span>
                        <span style="width: 20%;height: 20px;background-color: #F7F8FA;display: block;float: left;margin: 10px;"></span>
                    </div>
                    <div style="height: 40px;padding-left: 20px;line-height: 40px;">
                        <span style="color: #76C8F6;float: left;">4</span>
                        <span style="width: 20%;height: 20px;background-color: #F7F8FA;display: block;float: left;margin: 10px;"></span>
                        <span style="width: 20%;height: 20px;background-color: #F7F8FA;display: block;float: left;margin: 10px;"></span>
                        <span style="width: 20%;height: 20px;background-color: #F7F8FA;display: block;float: left;margin: 10px;"></span>
                    </div>
                    <div style="height: 85px;">
                         <p style="font-size: 14px;padding-left:20px;padding-top: 10px;">单选题</p>
                    <ul style="padding-left: 20px;padding-top: 5px;">
                        <li style="width: 10%;height: 40px;background-color: #F7F8FA;float: left;margin-right: 10px;border-radius: 5px;">
                            <span style="display: block;height: 50%;background: #76C8F6;color: #fff;text-align: center;">1</span>
                            <span style="display: block;height: 50%;color: #76C8F6;text-align: center;">A</span>
                        </li>
                        <li style="width: 10%;height: 40px;background-color: #F7F8FA;float: left;margin-right: 10px;">
                            <span style="display: block;height: 50%;background: #76C8F6;color: #fff;text-align: center;">2</span>
                            <span style="display: block;height: 50%;color: #76C8F6;text-align: center;">A</span>
                        </li>
                        <li style="width: 10%;height: 40px;background-color: #F7F8FA;float: left;margin-right: 10px;">
                            <span style="display: block;height: 50%;background: #76C8F6;color: #fff;text-align: center;">3</span>
                            <span style="display: block;height: 50%;color: #76C8F6;text-align: center;">C</span>
                        </li>
                        <li style="width: 10%;height: 40px;background-color: #F7F8FA;float: left;margin-right: 10px;">
                            <span style="display: block;height: 50%;background: #76C8F6;color: #fff;text-align: center;">4</span>
                            <span style="display: block;height: 50%;color: #76C8F6;text-align: center;">B</span>
                        </li>
                        <li style="width: 10%;height: 40px;background-color: #F7F8FA;float: left;margin-right: 10px;">
                            <span style="display: block;height: 50%;background: #76C8F6;color: #fff;text-align: center;">5</span>
                            <span style="display: block;height: 50%;color: #76C8F6;text-align: center;">C</span>
                        </li>
                    </ul>
                    </div>
                   <div style="height: 85px;">
                    <p style="font-size: 14px;padding-left:20px;padding-top: 10px;">多选题</p>
                    <ul style="padding-left: 20px;padding-top: 5px;">
                        <li style="width: 30%;height: 40px;background-color: #F7F8FA;float: left;margin-right: 10px;">
                            <span style="display: block;height: 50%;background: #76C8F6;color: #fff;text-align: center;">1</span>
                            <span style="display: block;height: 50%;color: #76C8F6;text-align: center;">ACB</span>
                        </li>
                    </ul>
                   </div>
                    
                </div>
                <div style="width: 60%;height: 800px;overflow-y: auto;">
                    <p style="font-weight: 600;">一、填空题</p>
                    <div style="background-color: #fff;margin-top: 10px;padding: 10px;">
                        <p style="font-size: 14px;">
                            <span style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">1</span>
                            <span style="padding-left: 10px;color: 14px;">项目总况中，机具指的是（    ）（    ）（    ）。</span>
                        </p>
                            <el-input placeholder="" v-model="input1" size="mini" style="margin-top: 10px;">
                                <template #prepend>1</template>
                            </el-input>
                            <el-input placeholder="" v-model="input2" size="mini" style="margin-top: 10px;">
                                <template #prepend>2</template>
                            </el-input>
                            <el-input placeholder="" v-model="input3" size="mini" style="margin-top: 10px;">
                                <template #prepend>3</template>
                            </el-input>
                       
                    </div>
                    <div style="background-color: #fff;margin-top: 10px;padding: 10px;">
                        <p style="font-size: 14px;">
                            <span style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">2</span>
                            <span style="padding-left: 10px;color: 14px;">土中冬粒组的相对含量称为土的（ ）。</span>
                        </p>
                            <el-input placeholder="" v-model="input4" size="mini" style="margin-top: 10px;">
                                <template #prepend>1</template>
                            </el-input>
                       
                    </div>
                    <div style="background-color: #fff;margin-top: 10px;padding: 10px;">
                        <p style="font-size: 14px;">
                            <span style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">3</span>
                            <span style="padding-left: 10px;color: 14px;">根据（    ）、（    ）、（    ）和（    ）等因素制订测设方案。</span>
                        </p>
                            <el-input placeholder="" v-model="input5" size="mini" style="margin-top: 10px;">
                                <template #prepend>1</template>
                            </el-input>
                            <el-input placeholder="" v-model="input6" size="mini" style="margin-top: 10px;">
                                <template #prepend>2</template>
                            </el-input>
                            <el-input placeholder="" v-model="input7" size="mini" style="margin-top: 10px;">
                                <template #prepend>3</template>
                            </el-input>
                            <el-input placeholder="" v-model="input8" size="mini" style="margin-top: 10px;">
                                <template #prepend>4</template>
                            </el-input>
                       
                    </div>
                   
                    <div style="background-color: #fff;margin-top: 10px;padding: 10px;">
                        <p style="font-size: 14px;">
                            <span style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">4</span>
                            <span style="padding-left: 10px;color: 14px;">三通一平指的是（    ）、（    ）、（    ）和（    ）。</span>
                        </p>
                            <el-input placeholder="" v-model="input9" size="mini" style="margin-top: 10px;">
                                <template #prepend>1</template>
                            </el-input>
                            <el-input placeholder="" v-model="input10" size="mini" style="margin-top: 10px;">
                                <template #prepend>2</template>
                            </el-input>
                            <el-input placeholder="" v-model="input11" size="mini" style="margin-top: 10px;">
                                <template #prepend>3</template>
                            </el-input>
                            <el-input placeholder="" v-model="input12" size="mini" style="margin-top: 10px;">
                                <template #prepend>4</template>
                            </el-input>
                       
                    </div>
                    <p style="font-weight: 600;padding-top: 10px;">二、单选题</p>
                    <div style="background-color: #fff;margin-top: 10px;padding: 10px;">
                        <p style="font-size: 14px;">
                            <span style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">1</span>
                            <span style="padding-left: 10px;color: 14px;">现场临建在什么模块展示（    ）？</span>
                        </p>
                        <el-radio-group v-model="radio" style="margin-top: 10px;">
                            <el-radio label="A">A 项目总况</el-radio>
                            <el-radio label="B">B 质量管理</el-radio>
                            <el-radio label="C">C 数字工地</el-radio>
                            <el-radio label="D">D 安全管理</el-radio>
                        </el-radio-group>
                    </div>
                    <p style="font-weight: 600;padding-top: 10px;">三、多选题</p>
                    <div style="background-color: #fff;margin-top: 10px;padding: 10px;">
                        <p style="font-size: 14px;">
                            <span style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">1</span>
                            <span style="padding-left: 10px;color: 14px;">视频监控在（    ）模块展示。</span>
                        </p>
                        <el-checkbox-group v-model="checkList">
                        <el-checkbox label="A" >A 项目总况</el-checkbox>
                        <el-checkbox label="B" >B 数字工地</el-checkbox>
                        <el-checkbox label="C" >C 质量管理</el-checkbox>
                        <el-checkbox label="D" >D 安全管理</el-checkbox>
                    </el-checkbox-group>
                    </div>
                </div>
                <div style="width: 15%;height: 500px;">
                    <div style="width: 100%;height: 100px;background-color: #fff;">
                        <p style="text-align: center;padding-top: 20px;">
                            <img src="@/assets/img/icon/daojishi.png" alt="" srcset="" style="vertical-align: middle;">
                            <span style="padding-left: 10px;">剩余时间</span>
                        </p>
                        <p style="text-align: center;padding-top:10px;font-size: 24px;color: #76C8F6;">
                            {{`0${RemainingTimeshi}:${RemainingTimefen}:${RemainingTimemiao}`}}
                        </p>
                    </div>
                    <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;margin-top: 200px;width: 100%;" @click="handpaper">交卷</el-button>
                </div>
            </div>
       
        </div>
        <el-dialog
        title="实训检验结果"
        v-model="dialogVisible" width="30%"  :modal="false"
        :before-close="handleClose">
        <div style="height: 100px;background: #F7F8FA;padding: 10px;">
            <div style="display: flex;justify-content:space-around;">
                <div>总题数</div>
                <div>正确数</div>
                <div>错误数</div>
            </div>
            <div style="display: flex;justify-content:space-around;margin-top: 20px;font-size: 20px;">
                <div>7</div>
                <div style="color:#76C8F6;">6</div>
                <div style="color: red;">1</div>
            </div>
        </div>
        <p style="text-align: end;color: #76C8F6;padding-top: 5px;cursor: pointer;" @click="wrongQuestion">错题及解析</p>
        <div style="text-align: center;margin-top: 20px;">
            <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;" @click="handleClose">返回</el-button>
        </div>
        
        </el-dialog>
        <el-dialog
        title="错题及解析"
        v-model="wrongVisible" width="30%"  :modal="false"
        :before-close="wrongVisibleClose">
        <div style="height: 100px;background: #F7F8FA;padding: 5px;margin-top: 10px;">
            <p style="padding-top: 5px;font-size: 16px;">1.土中冬粒组的相对含量称为土的（）</p>
            <p style="padding-top: 5px;font-size: 14px;"><span>您的答案：</span><span style="color: red;">颗粒度</span></p>
            <p style="padding-top: 5px;font-size: 14px;"><span>正确答案：</span><span style="color: #76C8F6;">粒径级配</span></p>
            <p style="padding-top: 5px;font-size: 14px;">解析：土中冬粒组的相对含量称为土的粒径级配</p>
        </div>
        <div style="height: 100px;background: #F7F8FA;padding: 5px;margin-top: 10px;">
            <p style="padding-top: 5px;font-size: 16px;">2.现场临建在什么模块展示（    ）？</p>
            <p style="padding-top: 5px;font-size: 14px;"><span>您的答案：</span><span style="color: red;">A</span></p>
            <p style="padding-top: 5px;font-size: 14px;"><span>正确答案：</span><span style="color: #76C8F6;">B</span></p>
            <p style="padding-top: 5px;font-size: 14px;">解析：土中冬粒组的相对含量称为土的粒径级配</p>
        </div>
        <div style="height: 100px;background: #F7F8FA;padding: 5px;margin-top: 10px;">
            <p style="padding-top: 5px;font-size: 16px;">3.视频监控在（    ）模块展示。</p>
            <p style="padding-top: 5px;font-size: 14px;"><span>您的答案：</span><span style="color: red;">A、C</span></p>
            <p style="padding-top: 5px;font-size: 14px;"><span>正确答案：</span><span style="color: #76C8F6;">C、B</span></p>
            <p style="padding-top: 5px;font-size: 14px;">解析：土中冬粒组的相对含量称为土的粒径级配</p>
        </div>
        <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;margin-top: 10px;">
        <el-pagination layout="prev, pager, next"  :total="50" />
        </div>
        
        <div style="text-align: center;margin-top: 20px;">
            <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;" @click="wrongVisibleClose">我知道了</el-button>
        </div>
        
        </el-dialog>


<!-- 自动交卷 -->
    <el-dialog
        v-model="Automatic" 
        title="自动交卷" 
        width="20%" 
        center
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        >
        <div style="display: flex;flex-direction: column;align-items: center;">
            <div style="color: #333333;font-size: 14px;font-weight: 500;">
                已到答题时间，将自动交卷
            </div>
            <div style="color: #999999;font-size: 14px;">
                3秒后将自动返回个人中心
            </div>
        </div>
        
    </el-dialog>

<!-- 交卷成功 -->
    <el-dialog
        v-model="handAutomatic" 
        title="交卷成功" 
        width="20%" 
        center
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        >
        <div style="display: flex;flex-direction: column;align-items: center;">
            <div style="color: #999999;font-size: 14px;">
                3秒后将自动返回个人中心
            </div>
        </div>

    </el-dialog>

    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed,watch ,} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {backUrl} from "@/js/util"
export default {
    setup () {
        const router = useRouter();
        const store = useStore();
        const state = reactive({
            input1:'',
            input2:'',
            input3:'',
            input4:'',
            input5:'',
            input6:'',
            input7:'',
            input8:'',
            input9:'',
            input10:'',
            input11:'',
            input12:'',
            radio:'',
            checkList:[],
            radio2:'',
            RemainingTimeshi: 0,
            RemainingTimefen: 58,
            RemainingTimemiao: 56,
            dialogVisible:false,
            wrongVisible:false,
            Automatic:false,//自动交卷
            handAutomatic:false,//交卷成功
        });
    const methods = {
            indexClick(){
                router.push('/home/studentCenter');
            },
            smartConstructionSite(){
                router.push('/home/intelligentConstructionDetail')
            },
            handpaper(){
                state.dialogVisible = true;
            },
            handleClose(){
                state.dialogVisible = false;
            },
            // 错题解析
            wrongQuestion(){
                state.wrongVisible = true;
            },
            wrongVisibleClose(){
                state.wrongVisible = false;
            },
    };
    
    watch(state.Automatic,(newVal,oldVal)=>{
                console.log(newVal,oldVal)
                if(newVal == true){
                    setTimeout(()=>{
                        state.Automatic = false
                        router.push('/home/studentCenter');
                    },3000);
                }
            },{
                immediate:true,
                deep:true,
            });



        return{
            ...toRefs(state),
            ...methods,
        }
    }
}
</script>
<style  lang="scss" scoped>
#courseIntroductionDetails{
    height: 100%;
    position: relative;
    background: #F7F8FA;
    .box{
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
    }
    .breadCrumbs{
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
    }
    .rightBox{
        width: 39%;
        height:800px;
        background: #fff;
        float: right;
        overflow-y: auto;
    }
    
   :deep .el-collapse-item__header{
        background: #F7F8FA;
    }
    :deep .el-collapse-item__wrap{
        border: none;
    }
    .titleSystemClass{
        width: 100%;
        height: 60px;
        background: #fff;
        text-align: center;
        line-height: 60px;
        font-size: 18px;
        font-weight: 800;
    }
    .system{
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }
    div::-webkit-scrollbar {
    width: 4px;
  }
  div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    opacity: 0.2;;
  }
  div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
  }

}
</style>